:root {
    --app-min-width: 1398px;
    --inner-min-width: 1100px;
    --color1: #90684c;
    --colorbg: #fafafa;

    --theme_oringinal: linear-gradient(130deg, #fe8bb5, #d5c5dd);
    --theme_hover_original: #e77996;
    --theme_orange: linear-gradient(323deg,#f99d4f,#fbcea7);
    --theme_hover_orange: #f99d4f;
    --theme_blue: linear-gradient(323deg,#5eb8bc,#62dce0);
    --theme_hover_blue: #5eb8bc;
    --new_theme_original: #F893B9;
}

html,
body, body>div:first-child, div#__next, div#__next>div {
    height: 100%;
}

body {
    background-color: var(--colorbg);
}

.c1 {
    color: var(--color1);
}
.fl {
    display: flex;
}
.grid {
    display: grid;
}

.align_center {
    align-items: center;
}

.justify_center {
    justify-content: center;
}
.justify_end {
    justify-content: end;
}
.justify_start {
    justify-content: start;
}
.point {
    cursor: pointer;
}
.app_min_width {
    width: var(--app-min-width);
}
.gap5 {
    gap: 5px;
}
.gap10 {
    gap: 10px;
}
.gap15 {
    gap: 15px;
}
.gap20 {
    gap: 20px;
}
.gap25 {
    gap: 25px;
}
.gap30 {
    gap: 30px;
}
.gap40 {
    gap: 40px;
}

.fz12 {
    font-size: 12px;
}
.fz13 {
    font-size: 13px;
}
.fz14 {
    font-size: 14px;
}
.fz15 {
    font-size: 15px;
}
.fz16 {
    font-size: 16px;
}
.fz18 {
    font-size: 18px;
}
.mauto {
    margin: auto;
}

.m0 {
    margin: 0 !important;
}

.m0auto {
    margin: 0 auto;
}
.a_w {
    color: white;
    outline: 0 none;
    transition: all .2s linear;
    text-decoration: none;
}
.a_w:visited {
    color: white;
}
.a_b {
    color: #333;
    outline: 0 none;
    transition: all .2s linear;
    text-decoration: none;
}
.a_b:visited {
    color: #333;
}
input {
    outline: none;
    background: transparent;
    border: 0;
    width: 100%;
    font-size: 15px;
}
input::placeholder {
    padding: 1px 2px;
    font-size: 15px;
}
.bgfa {
    background-color: #fafafa;
}

.btn_sty1 {
    border-radius: 41px;
    color: #fff;
    font-size: 20px;
    line-height: 50px;
    background: var(--theme_orange);
    width: 100%;
    border: none;
    cursor: pointer;
    height: 52px;
}
.theme_orange {
    background: var(--theme_orange);
}
.theme_blue {
    background: var(--theme_blue);
}
.theme_oringinal {
    background: var(--theme_oringinal);
}

.btn_gray{
    background: gray !important;

}
.btn_gray2 {
    background: #707070 !important;
}

.btn_style2 {
    padding: 12px 20px !important;
    width: 50% !important;
}
.btn_style_mobile2 {
    padding: 12px 20px !important;
    width: 100% !important;
    margin-left: 5px;
    margin-top: 5px;
}
.btn_style3 {
    /* padding: 10px 5px !important; */
    width: 100% !important;
}

.width100{
    width: 100px !important;
}
.btn_dl_sty {
    background: linear-gradient(#f08bad,#ff337c);
    color: #fff;
    border-radius: 8px;
    padding: 10px;
    width: fit-content;
    border: none;
    cursor: pointer;
}
.btn_sty2 {
    background: linear-gradient(#f08bad,#ff337c);
    color: #fff;
    border-radius: 8px;
    padding: 10px;
    width: 84%;
    border: none;
    cursor: pointer;
}
.btn_sty3 {
    background-color: #ab99cc;
    color: #fff;
    border-radius: 6px;
    font-size: 14px;
    height: 36px;
    line-height: 36px;
    width: 100px;
    border: none;
}
.btn_sty4 {
    background-color: #6adbdc;
    color: #fff;
    border-radius: 6px;
    font-size: 14px;
    height: 36px;
    line-height: 36px;
    width: 100px;
    border: none;
}
.btn_sty5 {
    background-color: #d2bda2;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    max-width: 945px;
    min-width: 249px;
    border: none;
}
.btn_sty6 {
    color: #fff;
    font-size: 20px;
    line-height: 50px;
    background: linear-gradient(#f08bad,#ff337c);
    width: 100%;
    border: none;
    cursor: pointer;
    text-align: center;
}
.btn_sty7 {
    background: white;
    color: #ddb980;
    border-radius: 8px;
    padding: 5px;
    padding-inline: 12px;
    width: 84%;
    border: none;
    cursor: pointer;
    border: 1px solid #ddb980;
}
.btn_sty8 {
    background: #FF8E9D;
    color: #fff;
    border-radius: 8px;
    padding: 5px;
    width: 84%;
    border: none;
    cursor: pointer;
}
.fw100 {
    font-weight: 100;
}
.fw200 {
    font-weight: 200;
}
.fw300 {
    font-weight: 300;
}
.fw400 {
    font-weight: 400;
}
.fw500 {
    font-weight: 500;
}

.d_none {
    text-decoration: none;
}
.relative {
    position: relative;
}
.abs {
    position: absolute;
}
.m10{
    margin: 10px;
}
.mt0{
    margin-top: 0;
}
.mt20 {
    margin-top: 20px;
}
.mt5 {
    margin-top: 5px;
}
.mt10 {
    margin-top: 10px;
}
.mt28 {
    margin-top: 28px;
}
.mt40 {
    margin-top: 40px;
}
.mb20 {
    margin-bottom: 20px;
}
.mt120 {
    margin-top: 120px;
}
.pblock10 {
    padding-block: 10px;
    padding-left: 5px;
}
.pinline10 {
    padding-inline: 10px;
}
.pl20 {
    padding-left: 20px;
}
.common_modal {
    width: 393px !important;
    height: 180px !important;
}
.vip_modal {
    max-width: 320px;
    min-height: 320px;
    padding: 0px !important;
}
.zip_modal {
    max-width: 760px;
    width: inherit;
    min-height: 185px;
    padding: 0px !important;
}
.buy_topic_modal {
    max-width: 320px;
    min-height: 200px;
    padding: 0px !important;
}
.notice_modal{
    max-width: 500px;
    /* min-height: 200px; */
    padding: 0 !important;
    width: inherit;
    text-align: unset;
    top: 20%;
}
.notice_msg_modal{
    max-width: 600px;
    /* min-height: 200px; */
    padding: 0 !important;
    /* width: inherit; */
    text-align: center;
    top: 28%;
}
.top0 {
    top: 0px;
}
.fl1 {
    flex: 1;
    text-align: center;
}
.text_center {
    text-align: center;
}
.mlauto {
    margin-left: auto;
}
.justify_between {
    justify-content: space-between;
}
.w25 {
    width: 25px;
}
.ml40{
    margin-left: 40px;
}
.no-background{
    background: none !important;
}
.w100 {
    width: 100%;
}
.of_cover {
    object-fit: cover;
}
.inner_min {
    max-width: var(--inner-min-width);
    min-width: 300px;
    margin: 0 auto;
}
.mt9 {
    margin-top: 9px;
}
.text_line {
    text-decoration: line-through
}
.con_lay {
    display: grid;
    gap: 40px;
    padding: 20px;
}
.red {
    color: red;
    font-size: 14px;
}
.bold {
    font-weight: bold;
    font-size: 20px;
}
.kf{
    color: #21b2f5;
    cursor: pointer;
}
.small_b {
    font-size: 13px;
}
.small_g {
    font-size: 13px;
    color: #777777;;
}
select {
    background: #f1f2f4;
    border: none;
    border-radius: 6px;
    margin-right: 4px;
    outline: none;
    padding: 4px;
    width: 80px;
    font-size: 12px;
    height: 25px;
}
.mask {
    background: rgba(0,0,0,.7);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 11000;
}

.s_h {
    color: #333;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding-left: 10px;
    margin-top: 10px;
}

.fl_wrap {
    flex-wrap: wrap;
}
.category_wrap{
    display: grid;
    grid-template-columns: 1fr 174px;
}
.mw1100 {
    width: 1200px;
    margin: auto;
}
.mh700 {
    min-height: 700px;
}
.to {
    overflow: hidden;
    height: 33px;
    /* text-overflow: ellipsis; */
    /* height: 20px; */
    /* white-space: nowrap; */
    margin-top: 5px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.bl {
    color: blue;
}

.main {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.main>div:nth-child(1) {
    line-height: 40px;
}
.main>div:nth-child(2) {
    font-size: 14px;
    color: #E77996;
    cursor: pointer;
}

.icon {
    width: 100%;
    height: 100%;
}



@media only screen and (max-width: 600px) {
    .icon {
        width: 100%;
        height: 100%;
    }
    .btn_style2 {
        padding: 12px 20px !important;
        width: 45% !important;
    }
    
    .notice_modal{
        max-width: 300px !important;
        top: 20% !important;
    }
    .notice_msg_modal{
        max-width: 300px !important;
        top: 28% !important;
    }
    .category_wrap{
        grid-template-columns: 1fr auto;
    }
    .btn_dl_sty{
        font-size: 14px;
    }
    .zip_modal{
        min-height: 200px;
        width: 90%;
    }
    .plr{
        padding-left: 10px;
        padding-right: 10px;
    }
    .con_lay {
        grid-gap: 20px;
        gap: 20px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
        padding-top: 0px;
    }
    .mw1100 {
        width: 100%;
    }
    .hideh {
        display: none;
    }
    .main {
        padding-left: 10px;
        padding-right: 10px;
    }
    .sh_ico img{
        width: 80% !important;
        object-fit: fill !important;
    }
    .sh_ico div {
        text-align: center;
    }
    .sh_ico {
        align-items: center;
    }
    .sdw {
        height: 400px !important;
    }
    .sdw_img {
        height: 400px !important;
    }
    .header_orange {
        background: var(--theme_orange);
    }
    .header_blue {
        background: var(--theme_blue);
    }
    .header_original {
        background: var(--theme_oringinal);
    }
    .head_space {
        margin-top: 52px !important;
    }
    .info_input > input { 
        width: 80% !important;
    }
    .swiper-slide { 
        width: 100% !important;
    }
    .pink_badge{
        font-size: 0.9rem !important;
    }
}

video {
    width: 100%;
    height: 100%;
}
audio {
    width: 300px;
    height: 54px;
}
#qrcode_img_hide {
    display: none;
}
.br10 {
    border-radius: 10px;
}
.br20 {
    border-radius: 20px;
}
.lt {
    border-top: 1px solid #d1d1d1;
}
.lb {
    border-bottom: 1px solid #d1d1d1;
}
.mb10 {
    margin-bottom: 10px;
}
.pad10 {
    padding: 10px;
}
img {
    object-fit: cover;
}
.mt80 {
    margin-top: 72px;
}
.ht {
    color: #333;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
}
.text_over {
    text-overflow: hidden;
    overflow: hidden;
}
.mr10 {
    margin-right: 10px;
}
.h800 {
    min-height: 800px;
}
.h900 {
    min-height: 900px;
}
.h1200 {
    min-height: 1000px;
}
.aimg {
    width: 100%;
    max-height: 56px;
    height: 56px;
    object-fit: contain !important;
}
.di {
    /* height: 200px; */
    flex: 1;
}
.di img {
    object-fit: contain;
}
.h100 {
    height: 100%;
}
.y_scroll {
    overflow-y: scroll;
}
.vjs_video_3-dimensions {
    width: 100% !important;
    height: 400px;
}

.fill {
    object-fit: cover;
}
.fill1 {
    object-fit: fill;
    height: 100%;
    width: 100%;
}
.i_cvr {
    filter: blur(5px);
    opacity: 0.8;
    z-index: -1;
    position: absolute;
    top: 0px;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover !important;
}
.sdw {
    height: 400px;
}
.tese_sdw {
    height: 600px;
}
.sh_ico img{
    width: 200px;
    object-fit: contain;
}
.wauto {
    width: auto;
    margin-right: 10px;
}
.newsContent {
    display: none;
}
.head_space {
    margin-top: 82px;
    background-color: #fafafa;
}
.head_space > div {
    max-width: 1100px;
    padding: 20px;
    margin: 20px auto;
    min-height: 500px;
    background: #ebebeb;
}
.info_radio {
    width: 22px;
    height: 22px;
    background-color: #151515;
    border: 1px solid #2f2f2f;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}
.info_input > input {
    width: 200px;
    height: 16px;
    background: #151515;
    border: none;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 15px 42px 15px 12px;
    color: #c6c6c6;
    border: 1px solid #000;
    display: block;
    margin-bottom: 5px;
}

.fo_b {
    color: #fff;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 12px;
}
.fo_grey {
    font-family: Arial,Helvetica,sans-serif;
    font-size: 16px;
}
.fo_grey_h {
    font-family: inherit;
    font-weight: 400;
    line-height: 1.2;
    color: inherit;
    font-size: calc(16px * 1.75);
    margin: 0 0 calc(16px * 1.5);
}
.fo_grey p {
    margin: 0 0 calc(16px * 1.5);
    max-width: 100%;
    color: black;
    text-align: justify;
}

.fo_title {
    color: black;
    font-size: 20px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 10px;
}
.fo_b p {
    color: black;
    margin-bottom: 10px;
}
.fo_grey a {
    color: #FA528A;
    outline-style: none;
    text-decoration: none;
}

.fo_b a {
    color: #FA528A;
    outline-style: none;
    text-decoration: none;
}
.fo_p_s {
    font-weight: 700;
    margin-inline: 10px;
}



.callout {
    background-color: white;
    /* border: 1px #dedede; */
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.14);
    padding: calc(16px * 1.5) 20px;
    margin-bottom: calc(16px * 1.5);
}
ul {
    margin: 0 0 calc(16px * 1.5);
    color: #818a91;
    line-height: 1.5;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}
li {
    margin: calc(16px * 1.5 / 2);
}
h4 {
    font-size: calc(16px * 1.25);
    margin: 0 0 calc(16px * 1.5);
}
.fo_s_2 {
    color: #363636;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 30px;
    margin-bottom: 30px;
}
.white {
    color: white;
}
.li_black {
    color: black;
}
textarea {
    border: solid 1px #d1d1d1;
}
.mt100 {
    margin-top: 100px;
}
.left {
    text-align: left;
}
.select_feedback select {
    width: 100%;
    height: 25px;
}
.vip_color {
    color: #FA528E;
}
.mask_normal {
    background-color: transparent;
    box-shadow: none;
}
/* button {
    outline: none;
} */
.pink_badge {
    background-color: #ff627d;
    padding: 1px 4px;
    font-size: 1rem;
    text-align: center;
    border-radius: 5px;
    top: 0px;
    color: white;
}

.sidenav {
    height: 100%;
    z-index: 9999;   
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    background: #00000080;
    width: 100%;
  }

  .sidenav .sidecontent{
    background-color: #fff;
    height: 100%;
    width: 76%;
    z-index: 999999;
    position: inherit;
    overflow: auto;
  }

  .sidenav .sideclose{
    width: 100%;
    height: 100%;
    position: inherit;
  }

  .sidenav .sidelogo{
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    height: 70px;
  }
  
  .sidenav .sidecontent .mainmenu {
    border-bottom: 1px solid #F9F9F9;
    padding: 15px;
    text-decoration: none;
    font-size: 16px;
    display: block;
    transition: 0.3s;
    color: #6A6A6A;
    display: flex;
    align-items: center;
    background: #FFF2F2;
  }

  .sidenav .sidecontent .mainmenu1 {
    border-bottom: 1px solid #F9F9F9;
    padding: 15px;
    text-decoration: none;
    font-size: 16px;
    display: block;
    transition: 0.3s;
    color: #6A6A6A;
    display: flex;
    align-items: center;
    background: #ffffff;
  }

  .sidenav .sidecontent .mainmenu2 {
    border-bottom: 1px solid #F9F9F9;
    padding: 15px;
    text-decoration: none;
    font-size: 16px;
    display: block;
    transition: 0.3s;
    color: #6A6A6A;
    display: flex;
    align-items: center;
    background: #F9F5FF;
  }

  .sidenav .sidecontent .mainmenucontent {
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 14px;
    width: 60%;
  }

  .sidenav .sidecontent .submenuwrapper {
    border-bottom: 1px solid #F9F9F9;
    background: #F7EBEF;
    padding: 15px 0;
    text-decoration: none;
    font-size: 14px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 10px;
    transition: 0.3s;
  }

  .sidenav .sidecontent .submenu{
    color: #6A6A6A;
  }

hr{
    border-top: 1px solid #e8dada;
}

/* slider */
.slider {
    position: relative;
    /* width: 80%;
    max-width: 800px; */
    overflow: hidden;
    height: 300px;
    margin: 10px 0;
}

.slidercontainer {
    display: flex;
    height: inherit;
    transition: transform 0.5s ease-in-out;
    transform: translateX(0);
    /* transform: translateX(-33.333%); */
}

.slide {
    min-width: 33.333%;
    transition: transform 0.5s ease-in-out;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.slide.first img{
    height: 90%;
    width: 98%;
    object-fit: cover;
    object-position: right;
    margin-top: 4%;
    margin-bottom: 10%;
    border-radius: 5px;
}

.slide.three img{
    height: 90%;
    width: 98%;
    object-fit: cover;
    object-position: left;
    margin-top: 4%;
    margin-bottom: 10%;
    border-radius: 5px;
}

.slide.main{
    min-width: 66%;
}

.slide.first::after{
    display: block;
    content: "";
    width: 33%;
    /* background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 1) 97%, rgba(0, 0, 0, 1) 100%); */
    background: white;
    height: 100%;
    position: absolute;
    opacity: 0.7;
    border-radius: 5px;
}
.slide.three::after{
    display: block;
    content: "";
    width: 33%;
    /* background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 9%, rgba(255,255,255,1) 100%); */
    background: white;
    height: 100%;
    position: absolute;
    opacity: 0.7;
    border-radius: 5px;
}
.svslide.first::after,
.svslide.three::after{
    display: none !important;
}
.slide.first, .slide.three{
    /* filter: opacity(0.4); */
}

.slide img {
    height: 100%;
    width: 98%;
    border-radius: 5px;    
    /* object-fit: contain; */
}

.arrow {
    border-radius: 100%;
    padding: 7px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2em;
    color: rgba(255, 255, 255, 0.7);
    background-color: rgba(0, 0, 0, 0.2);
    border: none;
    /* padding: 0.5em; */
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 10;
}
.arrowleft {
    left: 5px;
}

.arrowright {
    right: 5px;
}

.slider:hover .arrow {
    opacity: 1;
}

.arrow:hover {
    opacity: 1;
}

.svslide.first, .svslide.three{
    filter: opacity(1) !important;
}
.svslider{
    /* height: 146px !important; */
    height: 122px !important;
}
.svslide img{
    object-fit: contain !important;
    width: 100% !important;
}
.svslide.main{
    min-width: 33.333% !important;
}
.svslide{
    min-width: 100% !important;
}
.svslidercontainer{
    /* width: 89vw !important; */
    /* width: 19vw !important; */
}

@media only screen and (max-width: 600px) {
    .slide.first img{
        object-position: unset;
    }
    
    .slide.three img{
        object-position: unset;
    }
    
    .slide.first::after, .slide.three::after{
        display: none;
    }
    .slide.first, .slide.three{
        filter: opacity(1);
    }
    .slider{
        height: 146px;
    }
    .slide img{
        object-fit: contain;
        width: 100%;
    }
    .slide.main{
        min-width: 33.333%;
    }
    .slide{
        min-width: 100%;
    }
    .slidercontainer{
        width: 89vw;
        transform: translateX(0);
    }
  }