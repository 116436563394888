.container {
    width: 1440px;
    height: 1024px;
    margin: 0 auto;
    position: relative;
  }
  .container .swiper {
    height: 1024px;
  }
  .container .swiper img {
    width: 1440px;
    height: 1024px;
  }
  .container .top_container {
    position: absolute;
    top: 100px;
    right: 90px;
    display: flex;
    z-index: 2;
  }
  .container .top_container img {
    margin-left: 30px;
    cursor: pointer;
  }
  .container .down_container {
    display: grid;
    grid-template-rows: auto auto auto;
    position: absolute;
    bottom: 60px;
    right: 140px;
    z-index: 2;
  }
  .container .down_container img {
    margin-bottom: 20px;
    cursor: pointer;
  }
  .container .footer_container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 2;
  }
  .swiper-pagination {
    bottom: 0px;
  }
  .swiper-pagination .swiper-pagination-bullet {
    height: 14px;
    width: 8px;
    background: #fff;
    opacity: 0.5;
    border-radius: 4px;
  }
  .swiper-pagination .swiper-pagination-bullet-active {
    opacity: 1;
    height: 24px;
    width: 9px;
    border-radius: 5px;
  }

  .paginationContainer {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    z-index: 10;

}
.paginationDot {
    width: 12px;
    height: 40px;
    border-radius: 10px;
    cursor: pointer;

}
  