.common {
    background: rgba(0,0,0,.7);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 11000;
}
.body {
    background: #fff;
    border-radius: 5px;
    box-shadow: 1px 2px 3px #333;
    color: #fff;
    font-size: 1.2rem;
    left: 50%;
    line-height: 1.5;
    /* max-width: 414px;
    min-height: 388px; */
    padding: 10px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateZ(0) scale(1.185);
    transform: translate(-50%,-50%);
    width: 25%;
    min-width: 285px;
}
.content {
    color: #333;
}
.content > img{
    max-width: 600px;
    max-height: 600px;
    height: 100%;
    width: 100%;
}
.content_title {
    font-size: 18px;
    font-weight: 500;
}
.mid_title{
    font-size: 18px;
    font-weight: 700;
    text-align: center;
}
.content_inner_title {
    font-size: 16px;
}
.kefu_blue {
    font-size: 14px;    
}
.close {
    z-index: 1;
    background: rgba(0,0,0,.4);
    border: 2px solid;
    border-radius: 50%;
    color: #fff;
    padding: 5px 12px;
    position: absolute;
    right: -21px;
    top: -22px;
    cursor: pointer;
}
.fb_select > select {
    width: 100%;
    height: 30px;
}