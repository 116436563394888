.header {
    background: rgba(0,0,0,.3);
    position: fixed;
    top: 0;
    z-index: 100;
    height: 72px;
    width: 100%;
    color: #fff;
    font-weight: 700;
    line-height: 30px;
}

.scroll {
    backdrop-filter: saturate(180%) blur(20px)!important;
    background: rgba(0,0,0,.8);
    border-bottom-color: hsla(0,0%,100%,.04)!important;
    border-bottom: 1px solid transparent;
    box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12)!important;
}

.inner {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    height: 40px;
    max-width: 1300px;
}

.vipline {
    display: grid;
    grid-template-columns: 1rem auto 1rem;
    width: 180px;
}

.dropd {
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 4px solid #C6C6C6;
}
.dropd:hover {
    transform: rotate(180deg);
}
.ti_con {
    width: 502px;
}
.mt25 {
    margin-top: 18px;
}
.lh54 {
    line-height: 40px;
}
.top_item {
    top: 31px;
    width: 100%;
    background: #333;
    padding: 14px 0px 14px 0px;
    text-align: center;
    width: 95px;
    left: 0px;
}
.h40 {
    height: 40px;
    padding: 0px;
    padding-left: 10px;
    padding-right: 10px;
}
.bl {
    background-color: #333;
}
.line {
    border-bottom: 1px solid #ffffff;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 1px;
    padding-bottom: 4px;
    padding-top: 5px;
    padding-inline:10px;
}
.line > img {
    margin-right: 5px;
}
.menuline {
    background: #FF3471;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 1px;
    padding-bottom: 4px;
    padding-top: 5px;
    padding-inline:10px;
    margin-right: 20px;
}
.menuline > img {
    margin-right: 5px;
}
.loginoutline {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 7px;
    padding-bottom: 4px;
    padding-top: 5px;
    padding-inline:10px;
    color: #fff;
}
.loginoutline > img {
    margin-right: 5px;
}
.msgamtdesktop{
    position: absolute;
    top: -6px;
    right: -10px;
    font-size: 12px;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 20px;
    background-color: red;
    color: white;
    display: flex;
    padding: 1px;
    text-align: center;
    justify-content: center;
}
.msgamt{
    position: absolute;
    top: 0;
    right: 11px;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: red;
    color: white;
    display: flex;
    padding: 1px;
    text-align: center;
    justify-content: center;
}
.h_btm_con>div:nth-child(2) {
    display: flex;
}
.h_btm_con>div:nth-child(1) {
    display: none;
}
.logo_1 {
    width: 130px !important;
    height: 25px !important;
}
  

@media only screen and (max-width: 1075px) {
    .inner {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .ti_con {
        display: none !important;
    }
}

@media only screen and (max-width: 300px) {
    .inner>div:nth-child(2) {
        width: 148px !important;
        text-align: center;
        gap: 5px !important;
    }
    .logo_1 {
        padding-left: 5px;
        width: 65px !important;
        height: 13px !important;
    }
}

@media only screen and (max-width: 600px) {
    .logo_1 {
        width: 80px !important;
        height: 17px !important;
    }
    .inner {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .lct {
        display: none;
    }
    .lg1 {
        margin-top: 4px;
        justify-content: center;
        margin-left: 5px;
    }
    .lg2 {
        justify-content: center;
        width: 200px;
        font-size: 12px;
    }
    .h_btm_con > div:nth-child(2) {
        display: none;
    }
    .h_btm_con > div:nth-child(1) {
        display: block;
        text-align: right;
        padding-right: 10px;
        padding-top: 10px;
    }
    
    .header {
        height: 52px;
    }
    .ho_m {
        width: 123px;
        top: 47px;
        background: #F893B9;
        padding-block: 10px;
        right: 0;
        padding-top: 0px;
        padding-bottom: 0px;
        color: #fff;
        font-size: 12px;
        font-weight: 500;
    }
}
