.line {
    border-bottom: 1px solid #c7c7c7;
    /* margin-top: 10px; */
}
.inp {
    display: grid !important;
    grid-template-columns: auto 1fr 80px auto!important;
    min-height: 54px;
}
.inp > div > select {
    transform: translateY(-3px);
}
.err {
    color: grey;
    line-height: 20px;
    text-align: left;
}